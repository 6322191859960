import { InspectGalleryImageDialogComponent } from './../../../shared/components/inspect-gallery-image-dialog/inspect-gallery-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, take, takeUntil, Subject } from 'rxjs';
import { UserService } from './../../../core/user.service';

import * as fbDatabase from 'firebase/database';
import * as fbStorage from 'firebase/storage';
import { GalleryService } from '../../gallery.service';

export interface Gallery {
  id: string;
  title: string;
  images: Array<string>;
}
/**
 * GalleryPageComponent
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class GalleryPageComponent
 */
@Component({
  selector: 'svo-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss'],
})
export class GalleryPageComponent implements OnDestroy {
  
  public titleText: string = `Galerie`;

  public endOfLife: Subject<boolean> = new Subject();

  private _storage: fbStorage.FirebaseStorage = fbStorage.getStorage();

  private _galleriesRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries`);
  private _index: number;

  public itemsPerRow: number = 2; // IMPORTANT: Zero (0) is not allowed!
  public galleryRows: Array<any | null> = [];

  public gallery: BehaviorSubject<Gallery | null> = new BehaviorSubject<Gallery | null>(null);

  public constructor(private _galleryService: GalleryService, private _route: ActivatedRoute, public userService: UserService, private _dialog: MatDialog) {
    this._init();
  }

  public loadGallery() {
    this._galleryService.getGallery(this._index, gallerySnap => {
      this.gallery.next(gallerySnap.val())
    })
  }

  public save(index: number) {
    this._galleryService.saveGallery(this._index, () => { });
  }

  public editGallery(): void {
    this.gallery.pipe(take(1)).subscribe(gal => this._galleryService.editGallery(gal));
  }
  public deleteGallery(): void {
    // TODO: WARN DIALOG BEFORE DELETE
    this.gallery.pipe(take(1)).subscribe(gal => this._galleryService.deleteGallery(gal));
  }

  public addImage() {
    this.gallery.pipe(take(1)).subscribe(gallery => {
      if (gallery) {
        this._galleryService.addImage(gallery.id).subscribe();
      }
    })
  }

  public onOpenImage(imageData: { id: string, src: string, index: string | number }) {
    const dialogRef = this._dialog.open(InspectGalleryImageDialogComponent, {
      width: '100vw',
      height: '98vh',
      data: { galleryIndex: this._index, imageData: imageData }
    }).afterClosed().subscribe((reload) => { if (reload) { location.reload() } });
  }

  private _init() {
    this._route.params.pipe(takeUntil(this.endOfLife)).subscribe(routeParams => {
      this._cleanData();
      fbDatabase.get(this._galleriesRef).then((existingGalleries: fbDatabase.DataSnapshot) => {
        const allGalleries: Array<Gallery> = existingGalleries.val();
        this._index = allGalleries?.length && allGalleries.findIndex((gallery) => gallery.id === routeParams.id)
        this.loadGallery()
      })
    })

    this.gallery.pipe(takeUntil(this.endOfLife)).subscribe(gal => {
      if (gal && gal.images && gal.images.length > 0) {
        const promises = gal.images.map(img => this._loadImage(img))
        Promise.all(promises).then((values) => {
          const combined: Array<{ id: string, src: string, index: string | number }> = [];
          gal.images.forEach((element, index) => {
            combined.push({ id: element, src: values[index], index: index })
          });
          this._buildGallery(combined)
        }, (error) => console.error('File not found. Also happens when you read a recently added file too fast.', error));
      }
    })
  }

  private _buildGallery(arr: Array<{ id: string, src: string, index: string | number }>) {
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (index % this.itemsPerRow === 0) {
        this.galleryRows.push([element, null])
      } else {
        this.galleryRows[Math.floor(index / this.itemsPerRow)][index % this.itemsPerRow] = element;
      }
    }
  }

  private _loadImage(path: string): Promise<any> {
    const imageRef = fbStorage.ref(this._storage, `gallery/${path}`);
    return fbStorage.getDownloadURL(imageRef);
  }

  private _cleanData(): void {
    this.galleryRows = [];
    this._index = 2;
    this.gallery.next(null);
  }

  ngOnDestroy() {
    this.endOfLife.next(true);
  }
}

import packageInfo from '../../package.json';
export interface Environment {
  /**
   * Mode in which the app is running.
   * `production=true` is used for production mode. There shouldn't be any logging or use of mock data.
   *
   * @type {boolean}
   * @memberof Environment
   */
  production: boolean;

  /**
   * The current version of the app. The version is oriented on semver.org versioning.
   *
   * @type {string}
   * @memberof Environment
   */
  VERSION: string;

  /**
   * The configuration to connect this app to firebase.
   *
   * @type {*}
   * @memberof Environment
   */
  firebaseConfig: FirebaseConfig;
}

export interface FirebaseConfig {
  // tslint:disable-next-line:completed-docs
  apiKey: string;
  // tslint:disable-next-line:completed-docs
  authDomain: string;
  // tslint:disable-next-line:completed-docs
  databaseURL: string;
  // tslint:disable-next-line:completed-docs
  projectId: string;
  // tslint:disable-next-line:completed-docs
  storageBucket: string;
  // tslint:disable-next-line:completed-docs
  messagingSenderId: string;
  // tslint:disable-next-line:completed-docs
  appId: string;
  // tslint:disable-next-line:completed-docs
  measurementId: string;
}

export const environment: Environment = {
  production: false,
  VERSION: packageInfo.version,
  firebaseConfig: {
    apiKey: 'AIzaSyDARIMFpqxkIgvdbbTcMePGV7yCN0Wxlts',
    authDomain: 'schuetzenvereine.firebaseapp.com',
    databaseURL: 'https://schuetzenvereine.firebaseio.com',
    projectId: 'schuetzenvereine',
    storageBucket: 'schuetzenvereine.appspot.com',
    messagingSenderId: '497017252608',
    appId: '1:497017252608:web:0c35832993f514b96ea1e5',
    measurementId: 'G-5Z5901R19H',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

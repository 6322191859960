import { AuthService } from 'src/app/core/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { UserService } from '../core/user.service';

/**
 * Guard to make sure routes can only be activated if specific conditions are met.
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
  /**
   * Creates an instance of AuthGuard.
   *
   * @author Severin Klug <s.klug@optica.de>
   * @param {Router} router
   * @memberof AuthGuard
   */
  public constructor(private router: Router) { }

  /**
   * Implementation of canActivate. checks if the there is a current firebase user.
   * If currentUser is not null, the route can be activated.
   *
   * @returns {Promise<boolean>}
   * @author Severin Klug <s.klug@optica.de>
   * @memberof AuthGuard
   */
  public canActivate(): Promise<boolean> {
    if (!!getAuth().currentUser) {
      return new Promise((resolve, reject) => resolve(true));
    } else {
      this.router.navigate(["landing"]); // TODO: return UrlTree instead https://angular.io/api/router#functions
      return new Promise((resolve, reject) => resolve(false));
    }
  }

  canLoad() {
    if (!!getAuth().currentUser) {
      return true
    } else {
      this.router.navigate(["landing"]); // TODO: return UrlTree instead https://angular.io/api/router#functions
      return false
    }
  }
}

<svo-title-image [titleImage]="'assets/images/forest.jpeg'" [titleText]="titleText"></svo-title-image>
<h1>
  {{ (gallery | async)?.title }}
  <button *ngIf="!((userService.currentUser$ | async) === (undefined || null))" mat-button (click)="editGallery()">
    <mat-icon class="non-smartphone-icon">edit</mat-icon>
  </button>
  <button *ngIf="!((userService.currentUser$ | async) === (undefined || null))" mat-button (click)="deleteGallery()">
    <mat-icon class="non-smartphone-icon">delete</mat-icon>
  </button>
</h1>

<button *ngIf="!((userService.currentUser$ | async) === (undefined || null))" mat-raised-button color="primary" (click)="addImage()">hinzufügen</button>
<table>
  <tr *ngFor="let row of galleryRows">
    <td *ngFor="let item of [].constructor(itemsPerRow); let i = index">
      <mat-card *ngIf="row[i]" (click)="onOpenImage(row[i])">
        <mat-card-header>
          <!-- <mat-card-title>Titel des Bildes</mat-card-title> -->
        </mat-card-header>
        <mat-card-content>
          <img mat-card-image [src]="row[i]['src']" />
        </mat-card-content>
      </mat-card>
    </td>
  </tr>
</table>

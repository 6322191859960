<mat-card class="example-card">
  <mat-card-header>
    <img mat-card-avatar src="assets\logo\logo-sv-zimmern-farbig_klein.svg" />
    <mat-card-title>{{ event?.title }}</mat-card-title>
    <mat-card-subtitle>{{ event?.dateTimestamp | date : 'dd.MM.YYYY - H:mm' }}</mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image [src]="(image | async) || 'assets/logo/logo-sv-zimmern-farbig_klein.svg'" alt="Kein Bild verfügbar" />
  <mat-card-content>
    <p>
      {{ event?.description }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="onShare()">
      Teilen
      <mat-icon aria-hidden="false" aria-label="Example home icon">share</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>

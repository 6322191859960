import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * FooterComponent
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class FooterComponent
 */
@Component({
  selector: 'svo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent{
  /**
   * Version of the app
   *
   * @memberof FooterComponent
   */
  public version: string = environment.VERSION;
}

import { Component } from '@angular/core';

@Component({
  selector: 'svo-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {

}

import { Injectable } from '@angular/core';
import { User, getAuth, updateProfile } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';

/**
 * Singleton Service to manage Firebase Users.
 *
 * @author Severin Klug <s.klug@optica.de>
 * @singleton
 * @export
 * @class UserService
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   * Observable of the current firebase user.
   *
   * @type {BehaviorSubject<User>}
   * @memberof UserService
   */
  public currentUser$: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor() {
    getAuth().onIdTokenChanged((firebaseUser: User) => {
      if (!!firebaseUser) {
        this.currentUser$.next(firebaseUser);
      } else {
        this.currentUser$.next(null);
      }
    });
  }

  /**
   * Returns a snapshot of the current user.
   * Prefer Observable currentUser$.
   *
   * @returns {User}
   * @author Severin Klug <s.klug@optica.de>
   * @memberof UserService
   */
  public getCurrentUser(): User {
    return getAuth().currentUser;
  }

  /**
   * Method to update the current firebase user.
   *
   * @param {ProfileData} value
   * @returns {Promise<void>}
   * @author Severin Klug <s.klug@optica.de>
   * @memberof UserService
   */
  public updateCurrentUser(value: ProfileData): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const user: User = getAuth().currentUser;
      updateProfile(user, {
        displayName: value.displayName,
        photoURL: user.photoURL,
      })
        .then(res => {
          resolve(res);
        }, reject);
    });
  }
}

/**
 * Profile Data which are used for the firebase user.
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @interface ProfileData
 */
export interface ProfileData {
  /**
   * The name to be displayed.
   *
   * @type {string}
   * @author Severin Klug <s.klug@optica.de>
   * @memberof ProfileData
   */
  displayName?: string;

  /**
   * Path to the users profile picture.
   *
   * @type {string}
   * @author Severin Klug <s.klug@optica.de>
   * @memberof ProfileData
   */
  photoURL?: string;
}

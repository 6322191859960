import { ImageCropperModule } from 'ngx-image-cropper';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarEventComponent } from './components/calendar-event/calendar-event.component';
import { ContextPipe as ContextPipe } from './pipes/context.pipe';
import { TitleImageComponent } from './components/title-image/title-image.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { MomentPipe } from './pipes/moment.pipe';
import { InspectGalleryImageDialogComponent } from './components/inspect-gallery-image-dialog/inspect-gallery-image-dialog.component';
@NgModule({
  declarations: [
    CalendarComponent,
    CalendarEventComponent,
    ContextPipe,
    TitleImageComponent,
    PageTitleComponent,
    MomentPipe,
    InspectGalleryImageDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    ImageCropperModule,
  ],
  exports: [
    CalendarComponent,
    CalendarEventComponent,
    TitleImageComponent,
    ContextPipe,
    ImageCropperModule,
    MomentPipe
  ]
})
export class SharedModule { }

import { environment } from './../environments/environment';
import { initializeApp } from 'firebase/app';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FlexLayoutModule } from "@angular/flex-layout";

/**
 * Root module for the app.
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class AppModule
 */
@NgModule({
  declarations: [AppComponent],
  imports: [FlexLayoutModule, BrowserModule, AppRoutingModule, BrowserAnimationsModule, CoreModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor() {
    initializeApp(environment.firebaseConfig);
  }
}

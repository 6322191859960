import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, Renderer2, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';

/**
 * https://onthecode.co.uk/angular-material-calendar-component/
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class CalendarComponent
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'svo-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements AfterViewInit, OnChanges {
  @ViewChild('calendar', { static: true }) calendar: MatCalendar<Moment>;
  selectedDate: Moment;

  @Input()
  events = []

  @Output()
  dateSelected: EventEmitter<moment.Moment> = new EventEmitter();

  @Output()
  monthSelected: EventEmitter<moment.Moment> = new EventEmitter();

  public calendarFormattedEvents: Array<{ key: string, value: string }> = []

  constructor(private _renderer: Renderer2) { }

  public ngAfterViewInit() {
    this._setupArrowButtonListeners();
    this.highlightDays()
  }

  public ngOnChanges() {
    this.highlightDays();
  }

  public dateChanged() {
    this.calendar.activeDate = this.selectedDate;
    this.dateSelected.emit(this.selectedDate);
    this.highlightDays()
  }

  public monthChanged(date: moment.Moment) {
    this.monthSelected.emit(date);
    this.highlightDays()
  }

  private _createCalendarDateFormattedMap(): Array<{ key: string, value: string }> {
    this.calendarFormattedEvents = [];
    this.events.forEach(event => {
      const formatted = moment(event.dateTimestamp).format("LL"); // Format: "May 7, 2022"
      this.calendarFormattedEvents.push({ key: event.dateTimestamp, value: formatted })
    });
    return this.calendarFormattedEvents;
  }

  private _setupArrowButtonListeners() {
    const buttons = document.querySelectorAll('.mat-calendar-previous-button, .mat-calendar-next-button');

    if (buttons) {
      Array.from(buttons).forEach(button => {
        this._renderer.listen(button, 'click', () => {
          this.highlightDays();
          this.monthSelected.emit(this.calendar.activeDate);
        });
      });
    }
  }

  /**
   * Method to highlight certain days on the calendar.
   * This should be used when month selection changes.
   *
   * @param days: Array of strings in the format "February 20, 2020"
   */
  private highlightDays() {
    const dayElements = document.querySelectorAll(
      'mat-calendar .mat-calendar-table .mat-calendar-body-cell'
    );
    const days: Array<{ key: string, value: string }> = this._createCalendarDateFormattedMap();
    Array.from(dayElements).forEach((element) => {
      const matchingDay = days.find((d) => d.value === element.getAttribute('aria-label')) !== undefined;

      if (matchingDay) {
        this._renderer.addClass(element, 'available');
        this._renderer.setAttribute(element, 'title', 'Event 1');
      } else {
        this._renderer.removeClass(element, 'available');
        this._renderer.removeAttribute(element, 'title');
      }
    });
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../core/user.service';
import { GalleryService } from '../../../gallery/gallery.service';

@Component({
  selector: 'svo-inspect-gallery-image-dialog',
  templateUrl: './inspect-gallery-image-dialog.component.html',
  styleUrls: ['./inspect-gallery-image-dialog.component.scss']
})
export class InspectGalleryImageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { galleryIndex: string, imageData: { id: string, src: string, index: string | number } },
    private dialogRef: MatDialogRef<InspectGalleryImageDialogComponent>, public userService: UserService, private _galleryService: GalleryService) {
  }

  public deleteImage(): void {
    this._galleryService.deleteImage(this.data).then(() => {
      this.dialogRef.close(true); // true for reload
    })
  }

}


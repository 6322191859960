<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Titel</mat-label>
    <input matInput [formControl]="titleFormControl" [errorStateMatcher]="matcher" placeholder="Titel des Bildes" />
    <mat-error *ngIf="titleFormControl.hasError('required')"> Titel is <strong>required</strong> </mat-error>
  </mat-form-field>
</form>

<div class="container mt-5 text-center">
  <h3 class="mb-5">Bild zuschneiden</h3>
  <div class="col-md-12">
    <input type="file" (change)="onFileChange($event)" />
  </div>
  <div class="col-md-8">
    <!-- [resizeToWidth]="256" -->
    <image-cropper
      [imageChangedEvent]="imgChangeEvt"
      [maintainAspectRatio]="false"
      [aspectRatio]="4 / 4"
      format="png"
      (imageCropped)="cropImg($event)"
      (imageLoaded)="imgLoad()"
      (cropperReady)="initCropper()"
      (loadImageFailed)="imgFailed()"
    >
    </image-cropper>
  </div>
  <div class="col-md-4">
    <h6>Vorschau</h6>
    <img [src]="cropImgPreview" />
  </div>
</div>

<mat-dialog-actions>
  <div class="actions">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button
      *ngIf="imageData"
      mat-raised-button
      cdkFocusInitial
      [disabled]="titleFormControl.hasError('required')"
      [mat-dialog-close]="{ imageData: imageData, title: titleFormControl.value }"
      color="primary"
    >
      Fertig
    </button>
  </div>
</mat-dialog-actions>

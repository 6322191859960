import { CreateGalleryImageDialogComponent } from './create-gallery-image-dialog/create-gallery-image-dialog.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { EditGalleryDialogComponent } from './edit-gallery-dialog/edit-gallery-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import * as fbDatabase from 'firebase/database';
import * as fbStorage from 'firebase/storage';
import { DataSnapshot } from 'firebase/database';
import { Gallery } from './pages/gallery-page/gallery-page.component';
import moment from 'moment';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private _galleriesRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries`);
  private _storage: fbStorage.FirebaseStorage = fbStorage.getStorage();

  constructor(private _dialog: MatDialog, private _router: Router) { }

  /**
   * Edit AND Create Gallery Dialog
   *
   * @author Severin Klug <s.klug@optica.de>
   * @param {*} [gallery]
   * @memberof GalleryService
   */
  public editGallery(gallery?: Gallery | null) {
    const dialogRef = this._dialog.open(EditGalleryDialogComponent, {
      width: '300px',
      height: '200px',
      data: gallery
    });

    dialogRef.afterClosed().subscribe((data: Gallery) => {
      if (!data) { return }
      // use existing ID if its a edit Dialog or create new ID if its a create Dialog
      const id = gallery?.id || uuidv4();
      // Get length of all existing galleries in order to add a new index at the end of the array
      fbDatabase.get(this._galleriesRef).then((existingGalleries: fbDatabase.DataSnapshot) => {
        const allGalleries: Array<Gallery> = existingGalleries.val();
        let index: number = allGalleries?.length && allGalleries.findIndex(gal => gal.id === gallery?.id)
        if (index === -1 || index === undefined) {
          index = Number(existingGalleries.val()?.length) || 0;
        }
        const galleryRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries/${index}`);
        fbDatabase.set(galleryRef, { ...gallery, id: id, title: data?.title }).then(() => {
          this._router.navigate([`gallery/${id}`])
        });
      })
    });
  }

  public deleteGallery(gallery: Gallery | null) {
    fbDatabase.get(this._galleriesRef).then((existingGalleries: fbDatabase.DataSnapshot) => {
      const allGalleries: Array<Gallery> = existingGalleries.val();
      const index: number = allGalleries.findIndex(gal => gal.id === gallery?.id)
      allGalleries.splice(index, 1);
      const galleryRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries`);
      fbDatabase.set(galleryRef, allGalleries);
      this._router.navigate([``]);
    })
  }

  getGalleries(callBack: (snapshot: DataSnapshot) => unknown): fbDatabase.Unsubscribe {
    return fbDatabase.onValue(this._galleriesRef, callBack)
  }

  getGallery(index: number, callBack: (snapshot: DataSnapshot) => unknown): fbDatabase.Unsubscribe {
    const _galleryRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries/${index}`);
    return fbDatabase.onValue(_galleryRef, callBack)
  }

  public saveGallery(index: number, callBack: (snapshot: DataSnapshot) => unknown) {
    const _galleryRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries/${index}`);
    return fbDatabase.set(_galleryRef, callBack)
  }



  public addImage(galleryId: string): Observable<any> {
    const dialogRef = this._dialog.open(CreateGalleryImageDialogComponent, {
      width: '95vw',
      height: '95vh',
      data: null
    });

    return dialogRef.afterClosed().pipe(tap((data) => {
      this._uploadImage(galleryId, data);
    }));
  }

  public deleteImage(data: { galleryIndex: string, imageData: { id: string, src: string, index: string | number } }): Promise<void> {
    const _imageRefDatabase: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries/${data.galleryIndex}/images/${data.imageData.index}`);
    const _imageRefStorage = fbStorage.ref(fbStorage.getStorage(), `gallery/${data.imageData.id}`);
    return fbDatabase.remove(_imageRefDatabase).then(succ => {
      return fbStorage.deleteObject(_imageRefStorage)
    })
  }

  private _uploadImage(galleryId, data): Promise<void> {
    if (!data) { return new Promise((resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) => null) }
    const imageId = data?.id || uuidv4()
    const customMetadata = {
      customMetadata: {
        'title': data.title,
        'dateTimestamp': moment().valueOf().toString(),
        'contentType': 'image/png',
        'width': data.imageData.width.toString(),
        'height': data.imageData.height.toString(),
      }
    }

    return fbDatabase.get(this._galleriesRef).then((existingGalleries: fbDatabase.DataSnapshot) => {
      const allGalleries: Array<Gallery> = existingGalleries.val();
      const index: number = allGalleries?.length && allGalleries.findIndex(gal => gal.id === galleryId)
      const galleryRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/galleries/${index}/images`);
      fbDatabase.get(galleryRef).then((existingImages: fbDatabase.DataSnapshot) => {
        let allImagesIds: Array<Gallery> = existingImages?.val() || [];
        allImagesIds = [...allImagesIds, imageId]
        fbDatabase.set(galleryRef, allImagesIds).then(() => {
          // Add Image ID to Gallery
          const storageRef: fbStorage.StorageReference = fbStorage.ref(this._storage, `gallery/${imageId}`);
          fbStorage.uploadString(storageRef, data.imageData.base64, 'data_url', customMetadata).then(() => {
            location.reload();
            return true;
          }); // Add Image to Gallery
        });
      })
    })
  }
}

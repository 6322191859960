<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.imageData.id }}</mat-card-title>
    <button *ngIf="!((userService.currentUser$ | async) === (undefined || null))" mat-button (click)="deleteImage()">
      <mat-icon class="non-smartphone-icon">delete</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <img mat-card-image [src]="data.imageData.src" />
  </mat-card-content>
</mat-card>

import { Component, Input, OnChanges } from '@angular/core';
import { CalendarEvent } from './../../interfaces/calendar-event.interface';

import * as fbStorage from 'firebase/storage';
@Component({
  selector: 'svo-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent implements OnChanges {


  private _storage: fbStorage.FirebaseStorage = fbStorage.getStorage();
  private _storageRef: fbStorage.StorageReference;
  public image: Promise<string>;

  @Input()
  event: CalendarEvent;

  ngOnChanges() {
    if (this.event?.imageId) { this.image = this.loadImage(); }
  }

  loadImage(): Promise<string> {
    this._storageRef = fbStorage.ref(this._storage, 'events/' + this.event.imageId)
    return fbStorage.getDownloadURL(this._storageRef);
  }

  async onShare() {
    const shareData = {
      title: this.event.title,
      text: this.event.description,
      url: `https://svzimmern.com/publications/calendar/${this.event.dateTimestamp}`
    }
    try {
      await navigator.share(shareData)
    } catch (err) {
      console.warn(`Error: ${err}`);
    }
  }
}

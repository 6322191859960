import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material/material.module';
import { GalleryModule } from './../gallery/gallery.module';
import { SharedModule } from './../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MainTabNavComponent } from './main-tab-nav/main-tab-nav.component';

/**
 * CoreModule
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class CoreModule
 */
@NgModule({
  imports: [CommonModule, AppRoutingModule, MaterialModule, SharedModule, GalleryModule],
  declarations: [HeaderComponent, FooterComponent, MainTabNavComponent],
  exports: [CommonModule, HeaderComponent, FooterComponent, MainTabNavComponent],
})
export class CoreModule { }

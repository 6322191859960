import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GalleryService } from './../../gallery/gallery.service';
import { Component, Input } from '@angular/core';
import { UserService } from '../user.service';
import { Gallery } from 'src/app/gallery/pages/gallery-page/gallery-page.component';

export interface NavLink {
  /**
   * Label
   *
   * @type {string}
   * @memberof NavLink
   */
  label: string;

  /**
   * Link
   *
   * @type {string}
   * @memberof NavLink
   */
  link: string;
}

/**
 * Navigation that contains all pages and subpages.
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class MainTabNavComponent
 */
@Component({
  selector: 'svo-main-tab-nav',
  templateUrl: './main-tab-nav.component.html',
  styleUrls: ['./main-tab-nav.component.scss'],
})
export class MainTabNavComponent {
  /**
   * Array of all routes and labels of navigation tabs.
   *
   * @type {NavLink[]}
   * @memberof MainTabNavComponent
   */
  public navLinks: NavLink[];

  public galleries: BehaviorSubject<any> = new BehaviorSubject<any>([])

  public navBarClass: string = 'context-landing';

  /**
   * Dunno; Copy pasta.
   *
   * @memberof MainTabNavComponent
   */
  public activeLinkIndex: number = -1;

  public constructor(private _router: Router, public route: ActivatedRoute, public userService: UserService, public galleryService: GalleryService) {
    // set up listeners
    this._getGalleries();
    this._updateBannerClass();
    this._setupScrollBehaviour();

    this.navLinks = [
      {
        label: 'Willkommen',
        link: '/landing',
      },
      {
        label: 'Verein',
        link: '/club',
      },
      {
        label: 'Gallerie',
        link: '/gallery',
      },
      {
        label: 'Kontakt',
        link: '/contact',
      },
      {
        label: 'Beitreten',
        link: '/join',
      },
      {
        label: 'Login',
        link: '/login',
      },
      {
        label: 'Admin',
        link: '/admin',
      },
    ];
  }

  public createGallery(): void {
    this.galleryService.editGallery();
  }

  public navigateToGallery(gallery: Gallery): void {
    this._router.navigate([`gallery/${gallery.id}`]);
  }

  private _getGalleries(): void {
    this.galleryService.getGalleries((galleries) => { this.galleries.next(galleries.val()) });
  }

  private _updateBannerClass() {
    this._router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const urlFragments = event.url.split('/');
        urlFragments.shift()
        if (urlFragments[0] === 'landing') {
          this.navBarClass = `context-${urlFragments[0]}`;
        } else {
          this.navBarClass = `context-non-landing`;
        }
      });
  }

  private _setupScrollBehaviour() {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar")!.style.top = "0";
      } else {
        document.getElementById("navbar")!.style.top = "-400px";
      }
      prevScrollpos = currentScrollPos;
    }
  }
}

import { Subject, takeUntil } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy } from '@angular/core';
import * as fbDatabase from 'firebase/database';
import * as fbStorage from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { SlideImage } from '../../../landing/components/banner/banner.component';
import { EditBannerImageDialogComponent } from '../../edit-banner-image-dialog/edit-banner-image-dialog.component';
import moment from 'moment';
import { remove } from 'firebase/database';
import { deleteObject } from 'firebase/storage';

@Component({
  selector: 'svo-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.scss']
})
export class EditBannerComponent implements OnDestroy {
  public endOfLife: Subject<boolean> = new Subject();

  public inactiveBannerImages: Array<SlideImage | any> = [];
  public activeBannerImages: Array<SlideImage | any> = [];

  private _storage: fbStorage.FirebaseStorage = fbStorage.getStorage();
  private _storageRef: fbStorage.StorageReference;
  public image: Promise<string>;

  public constructor(public dialog: MatDialog) {
    this.loadBanners();
  }

  drop(event: CdkDragDrop<string[], string[], any> | Event | any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.saveBanners();
    }
  }

  public editBanner(banner: SlideImage, path: string, id?: string) {
    const activeBannersRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/banners/${path}`);
    fbDatabase.set(activeBannersRef, id || uuidv4());
  }

  public createBanner() {
    const dialogRef = this.dialog.open(EditBannerImageDialogComponent, {
      width: '600px',
      height: '800px',
      data: null
    });

    dialogRef.afterClosed().pipe(takeUntil(this.endOfLife)).subscribe((data: { imageData: ImageCroppedEvent, title: string }) => {
      if (!data) { return }
      const customMetadata = {
        customMetadata: {
          'title': data.title,
          'dateTimestamp': moment().valueOf().toString(),
          'contentType': 'image/png',
          'width': data.imageData.width.toString(),
          'height': data.imageData.height.toString(),
        }
      }
      const id = uuidv4();
      const activeBannersRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/banners/active/${id}`);
      fbDatabase.set(activeBannersRef, { id: id, title: data.title });

      const storageRef: fbStorage.StorageReference = fbStorage.ref(this._storage, `banners/${id}`)
      fbStorage.uploadString(storageRef, data.imageData.base64, 'data_url', customMetadata);
    });
  }

  public loadBanners() {
    // inactive Banners
    const inactiveBannersRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), '/banners/inactive');
    fbDatabase.onValue(inactiveBannersRef, (inactiveBanSnap: fbDatabase.DataSnapshot) => {
      if (inactiveBanSnap.val()) {
        this.inactiveBannerImages = Object.keys(inactiveBanSnap.val()).map((key) => inactiveBanSnap.val()[key]);
      } else {
        this.inactiveBannerImages = [];
      }
    })
    // active Banners
    const activeBannersRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), '/banners/active');
    fbDatabase.onValue(activeBannersRef, (activeBanSnap: fbDatabase.DataSnapshot) => {
      if (activeBanSnap.val()) {
        this.activeBannerImages = Object.keys(activeBanSnap.val()).map((key) => activeBanSnap.val()[key]);
      } else {
        this.activeBannerImages = [];
      }
    })
  }

  loadImage(path: string): Promise<string> {
    this._storageRef = fbStorage.ref(this._storage, `banner/${path}`)
    return fbStorage.getDownloadURL(this._storageRef);
  }

  public saveBanners() {
    const activeBannersRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/banners/active`);
    const inactiveBannersRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/banners/inactive`);
    const inactive = this.inactiveBannerImages.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
    const active = this.activeBannerImages.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})

    fbDatabase.set(inactiveBannersRef, inactive);
    fbDatabase.set(activeBannersRef, active);
  }

  public deleteBanner(banner) {
    const status = this.activeBannerImages.indexOf(banner) >= 0 ? 'active' : this.inactiveBannerImages.indexOf(banner) >= 0 ? 'inactive' : null;
    if (status) {
      const bannersRef: fbDatabase.DatabaseReference = fbDatabase.ref(fbDatabase.getDatabase(), `/banners/${status}/${banner.id}`);
      const storageRef: fbStorage.StorageReference = fbStorage.ref(this._storage, `banners/${banner.id}`)
      remove(bannersRef)
      deleteObject(storageRef);
    } else {
      console.warn('Could not remove element');
      return;
    }
  }

  ngOnDestroy() {
    this.endOfLife.next(true);
  }
}

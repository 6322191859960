import { EditBannerComponent } from './pages/edit-banner/edit-banner.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { AuthGuard } from '../core/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'media2014',
    pathMatch: 'full'
  },
  {
    path: 'editBanner',
    component: EditBannerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':id', component: GalleryPageComponent
  }
];

/**
 * GalleryRoutingModule
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class GalleryRoutingModule
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GalleryRoutingModule { }

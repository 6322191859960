import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: any | moment.Moment, ...args: any[]): any {
    const [format] = args;
    return moment(value).format(format);
  }
}

<button mat-raised-button color="primary" (click)="createBanner()">Hinzufügen</button>

<div cdkDropListGroup>
  <div class="example-container">
    <h2>Inaktiv</h2>

    <div cdkDropList [cdkDropListData]="inactiveBannerImages" class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let banner of inactiveBannerImages" cdkDrag>
        Titel: {{ banner.title }} | ID: {{ banner.id }}
        <button mat-button (click)="deleteBanner(banner)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
  </div>

  <div class="example-container">
    <h2>Aktiv</h2>

    <div cdkDropList [cdkDropListData]="activeBannerImages" class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let banner of activeBannerImages" cdkDrag>
        Titel: {{ banner.title }} | ID: {{ banner.id }}
        <button mat-button (click)="deleteBanner(banner)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextPipe } from '../../pipes/context.pipe';

@Component({
  selector: 'svo-title-image',
  templateUrl: './title-image.component.html',
  styleUrls: ['./title-image.component.scss']
})
export class TitleImageComponent implements AfterViewInit {
  @Input() titleImage: string = `assets/images/forest.jpeg`;
  @Input() titleText: string = ''

  public constructor(private _router: Router, private _route: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit() { }

  ngOnChanges() {
    console.log('change')
    if (this.titleText === '' || this.titleText === null || this.titleText === undefined) {
      this.titleText = new ContextPipe().transform(this._router.url.split('/').pop()!, true);
      this.cdr.detectChanges();
    } else {
      this.titleText.replace("\n", "<br>"); // FIXME: Doesnt work
    }
  }

  ngAfterViewInit() { }

}

import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Gallery } from '../pages/gallery-page/gallery-page.component';

@Component({
  selector: 'svo-edit-gallery-dialog',
  templateUrl: './edit-gallery-dialog.component.html',
  styleUrls: ['./edit-gallery-dialog.component.scss']
})
export class EditGalleryDialogComponent {
  public editGalleryForm: UntypedFormGroup;

  public constructor(
    private _fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditGalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: Gallery) {
    this.editGalleryForm = this._fb.group(
      {
        title: new UntypedFormControl(data?.title || undefined, [Validators.required]),
        id: data?.id
      },
    );
  }

  public confirm(): void {
    if (this.editGalleryForm.valid) {
      this.dialogRef.close(this.editGalleryForm.value)
    } else {
      console.error('Form invalid')
    }
  }

  public cancel() {
    this.dialogRef.close(null)
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import context from '../../../assets/context.de.json';

@Pipe({
  name: 'context'
})
export class ContextPipe implements PipeTransform {

  /**
   *
   *
   * @author Severin Klug <s.klug@optica.de>
   * @param {string} value
   * @param {...unknown[]} args [0] First param: If true: use default value
   * @returns {string}
   * @memberof ContextPipe
   */
  transform(value: string, ...args: unknown[]): string {
    return context[value] ? context[value] : args[0] ? 'SV-Zimmern' : value;
  }
}

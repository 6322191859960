<form [formGroup]="editGalleryForm">
  <mat-form-field>
    <mat-label>Titel</mat-label>
    <input formControlName="title" matInput placeholder="Titel" />
  </mat-form-field>
</form>

<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-button [disabled]="!editGalleryForm.valid" (click)="confirm()" cdkFocusInitial>{{ data ? 'Gallerie editieren' : 'Gallerie erstellen' }}</button>
</mat-dialog-actions>

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'svo-edit-banner-image-dialog',
  templateUrl: './edit-banner-image-dialog.component.html',
  styleUrls: ['./edit-banner-image-dialog.component.scss'],
})
export class EditBannerImageDialogComponent {
  public imgChangeEvt: any = '';
  public cropImgPreview: any = '';
  public imageData: any;
  public title: string = '';

  titleFormControl = new FormControl('', [Validators.required]);

  matcher = new MyErrorStateMatcher();

  public constructor(public dialogRef: MatDialogRef<EditBannerImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }

  cropImg(event: ImageCroppedEvent) {
    this.cropImgPreview = event.base64;
    this.imageData = event;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  public confirm(): void {
    if (this.imageData) {
      // this.dialogRef.close(this.createEventForm.value)
    } else {
      console.error('Form invalid');
    }
  }
}

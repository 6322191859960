import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'svo-create-gallery-image-dialog',
  templateUrl: './create-gallery-image-dialog.component.html',
  styleUrls: ['./create-gallery-image-dialog.component.scss']
})
export class CreateGalleryImageDialogComponent {
  public imgChangeEvt: any = '';
  public cropImgPreview: any = '';
  public imageData: any;
  public title: string = '';

  titleFormControl = new FormControl('', [Validators.required]);

  matcher = new ErrorStateMatcher();

  public constructor(public dialogRef: MatDialogRef<CreateGalleryImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }

  cropImg(event: ImageCroppedEvent) {
    this.cropImgPreview = event.base64;
    this.imageData = event;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  public cancel() {
    this.dialogRef.close(null)
  }
  public confirm(): void {
    if (this.imageData) {
      // this.dialogRef.close(this.createEventForm.value)
    } else {
      console.error('Form invalid')
    }
  }
}

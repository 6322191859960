import { Component } from '@angular/core';

/**
 * HeaderComponent
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class HeaderComponent
 */
@Component({
  selector: 'svo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent { }

import { Component } from '@angular/core';

/**
 * AppComponent
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class AppComponent
 */
@Component({
  selector: 'svo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * title
   *
   * @memberof AppComponent
   */
  public title: string = 'Schützenvereine';

  public constructor() { }
}

<div class="navbar" id="navbar">
  <div [ngClass]="navBarClass">
    <!--
  MOBILE DEVICE
     -->
    <div class="smartphone">
      <button mat-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon>Menü</button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/landing']">Willkommen</button>
        <button mat-menu-item [matMenuTriggerFor]="club">Verein</button>
        <button mat-menu-item [matMenuTriggerFor]="gallery">Galerie</button>
        <button mat-menu-item [matMenuTriggerFor]="publications">Veröffentlichungen</button>
        <button mat-menu-item [matMenuTriggerFor]="contact">Kontakt</button>
        <button *ngIf="(userService.currentUser$ | async) === (undefined || null)" mat-menu-item [routerLink]="['/join']">Mitglied werden!</button>
        <button *ngIf="userService.currentUser$ | async" mat-menu-item [routerLink]="['/join/members']">Mitglieder verwalten</button>
        <button *ngIf="(userService.currentUser$ | async) === (undefined || null)" mat-menu-item [routerLink]="['/login']">Login</button>
        <button *ngIf="userService.currentUser$ | async" mat-menu-item [routerLink]="['/admin']"><mat-icon>manage_accounts</mat-icon>Admin</button>
      </mat-menu>

      <mat-menu #club="matMenu">
        <button mat-menu-item [routerLink]="['/club/executive-board']">Vorstand</button>
        <button mat-menu-item [routerLink]="['/club/statute']">Satzung</button>
        <button mat-menu-item [routerLink]="['/club/shooting-range']">Schießstand</button>
      </mat-menu>

      <mat-menu #gallery="matMenu">
        <button *ngFor="let gallery of galleries | async" (click)="navigateToGallery(gallery)" mat-menu-item>
          {{ gallery.title }}
        </button>
        <button *ngIf="!((userService.currentUser$ | async) === (undefined || null))" mat-menu-item (click)="createGallery()">hinzufügen</button>
      </mat-menu>

      <mat-menu #publications="matMenu">
        <button mat-menu-item [routerLink]="['/publications/calendar']">Kalender</button>
        <button mat-menu-item [routerLink]="['/publications/press-releases']">Pressemitteilungen</button>
        <button mat-menu-item [routerLink]="['/publications/competition-results']">Wettkampfergebnisse</button>
      </mat-menu>

      <mat-menu #contact="matMenu">
        <!-- <button mat-menu-item [routerLink]="['/contact/contact-form']">Kontaktformular</button> -->
        <button mat-menu-item [routerLink]="['/contact/directions']">Anfahrt</button>
      </mat-menu>
    </div>

    <!--
  NON-MOBILE DEVICE
     -->
    <div class="non-smartphone">
      <table>
        <tr>
          <td>
            <a [routerLink]="['/landing']"><mat-icon class="non-smartphone-icon">star</mat-icon>Willkommen</a>
          </td>
          <td>
            <div class="dropdown">
              <button class="dropbtn">
                <mat-icon class="non-smartphone-icon">house</mat-icon>
                Verein
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <a [routerLink]="['/club/executive-board']"><mat-icon class="non-smartphone-icon">manage_accounts</mat-icon>Vorstand</a>
                <a [routerLink]="['/club/statute']"><mat-icon class="non-smartphone-icon">manage_accounts</mat-icon>Satzung</a>
                <!-- <a [routerLink]="['/club/club-history']"><mat-icon class="non-smartphone-icon">manage_accounts</mat-icon>Vereinsgeschichte</a> -->
                <a [routerLink]="['/club/shooting-range']"><mat-icon class="non-smartphone-icon">manage_accounts</mat-icon>Schießstand</a>
              </div>
            </div>
          </td>
          <td>
            <div class="dropdown">
              <button class="dropbtn">
                <mat-icon class="non-smartphone-icon">image</mat-icon>
                Gallerie
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <a *ngFor="let gallery of galleries | async" (click)="navigateToGallery(gallery)"
                  ><mat-icon class="non-smartphone-icon">image</mat-icon>{{ gallery.title }}
                </a>
                <button *ngIf="!((userService.currentUser$ | async) === (undefined || null))" mat-button (click)="createGallery()">
                  <mat-icon class="non-smartphone-icon">add</mat-icon>hinzufügen
                </button>
              </div>
            </div>
          </td>
          <td>
            <div class="dropdown">
              <button class="dropbtn">
                <mat-icon class="non-smartphone-icon">contact_support</mat-icon>
                Kontakt
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <!-- <a [routerLink]="['/contact/contact-form']"><mat-icon class="non-smartphone-icon">contact_support</mat-icon>Kontaktformular</a> -->
                <a [routerLink]="['/contact/directions']"><mat-icon class="non-smartphone-icon">location_on</mat-icon>Anfahrt</a>
              </div>
            </div>
          </td>
          <td>
            <img src="assets\logo\logo-sv-zimmern-farbig_klein.svg" alt="SV-Zimmern Logo" class="svg-logo" />
          </td>
          <td>
            <div class="dropdown">
              <button class="dropbtn">
                <mat-icon class="non-smartphone-icon">public</mat-icon>
                Veröffentlichungen
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <a [routerLink]="['/publications/calendar']"><mat-icon class="non-smartphone-icon">calendar_month</mat-icon>Kalender</a>
                <a [routerLink]="['/publications/press-releases']"><mat-icon class="non-smartphone-icon">public</mat-icon>Pressemitteilungen</a>
                <a [routerLink]="['/publications/competition-results']"><mat-icon class="non-smartphone-icon">public</mat-icon>Wettkampfergebnisse</a>
              </div>
            </div>
          </td>

          <td>
            <a *ngIf="userService.currentUser$ | async; else elseBlockJoin" [routerLink]="['/join/members']">
              <mat-icon class="non-smartphone-icon">manage_accounts</mat-icon>Mitglieder verwalten</a
            >
            <ng-template #elseBlockJoin>
              <a [routerLink]="['/join']"><mat-icon class="non-smartphone-icon">mood</mat-icon>Mitglied werden!</a></ng-template
            >
          </td>
          <td>
            <a *ngIf="userService.currentUser$ | async; else elseBlockLogin" [routerLink]="['/admin']"
              ><mat-icon class="non-smartphone-icon">settings</mat-icon>Admin</a
            >
            <ng-template #elseBlockLogin
              ><a [routerLink]="['/login']"><mat-icon class="non-smartphone-icon">settings</mat-icon>Login</a></ng-template
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

import { MaterialModule } from './../material/material.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GalleryRoutingModule } from './gallery-routing.module';
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { EditBannerComponent } from './pages/edit-banner/edit-banner.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditBannerImageDialogComponent } from './edit-banner-image-dialog/edit-banner-image-dialog.component';
import { GalleryService } from './gallery.service';
import { EditGalleryDialogComponent } from './edit-gallery-dialog/edit-gallery-dialog.component';
import { CreateGalleryImageDialogComponent } from './create-gallery-image-dialog/create-gallery-image-dialog.component';

/**
 * GalleryModule
 *
 * @author Severin Klug <s.klug@optica.de>
 * @export
 * @class GalleryModule
 */
@NgModule({
  declarations: [
    GalleryPageComponent,
    EditBannerComponent,
    EditBannerImageDialogComponent,
    EditGalleryDialogComponent,
    CreateGalleryImageDialogComponent
  ],
  imports: [
    CommonModule,
    GalleryRoutingModule,
    SharedModule,
    DragDropModule,
    MaterialModule
  ],
  providers: [GalleryService]
})
export class GalleryModule { }
